enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
  HEAD = 'HEAD',
}

enum APIVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

/**
 * 权限的类型
 */
enum PermissionType {
  MENU = 1,
  BUTTON = 2,
  FUNCTION = 3,
}

enum Platform {
  CONSOLE = 1,
  ORGANIZATION = 2,
  HXCONSOLE = 3,
  HXORGANIZATION = 4,
  XLCONSOLE = 5,
  XLORGANIZATION = 6,
}

/**
 * 是否院内版
 */
enum SocialOrHospital {
  SOCIAL = 0,
  HOSPITAL = 1,
}

/**
 * 院内版登录身份
 */
enum InnerHospitalAccountType {
  USER = 0,
  PATIENT = 1,
}

/**
 * 患者登录类型: 1-登记号 2-账号 3-短信验证码
 */
enum PatientAccountType {
  REGNO = 1,
  ACCOUNT = 2,
  MESSAGE = 3,
}

/**
 * 学历、性别、证件类型、婚姻状况
 */
const EducationDegree = ['', '小学以下', '小学', '初中', '高中', '中专', '大专', '本科', '硕士', '博士', '学龄前'];
const Gender = ['未知', '男', '女'];
const CredentialsType = ['', '身份证', '护照', '外国人永久居留（身份）证', '港澳往返内地通行证', '台湾往返内地通行证'];
const Marriage = ['', '未婚', '已婚', '离异', '丧偶', '<18岁不涉及', '其他'];

export {
  Method,
  APIVersion,
  PermissionType,
  Platform,
  EducationDegree,
  Gender,
  CredentialsType,
  Marriage,
  SocialOrHospital,
  InnerHospitalAccountType,
  PatientAccountType,
};
